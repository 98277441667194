import { Theme } from './theme.types'
import { keyframes } from '@emotion/react'
import { GOLDEN_RATIO, MAJOR_THIRD } from '../styleConstants'

/**
 * The point of this name is to make it easy to autoimport,
 * a unique name that's not long to type.
 */
export const mTheme: Theme = {
  ratio: {
    primary: MAJOR_THIRD,
    secondary: GOLDEN_RATIO,
  },
  prefersReducedMotion: '@media (prefers-reduced-motion: reduce)',
  breakpoint: {
    small: '@media(min-width: 640px)',
    belowSmall: '@media(max-width: 640px)',
    medium: '@media (min-width: 768px)',
    belowMedium: '@media(max-width: 768px)',
    large: '@media (min-width: 1024px)',
    belowLarge: '@media(max-width: 1024px)',
  },
  size: {
    XXS: '0.3rem',
    XS: '0.5rem',
    S: '0.8rem',
    M: '1.5rem',
    L: '2rem',
    XL: '3.9rem',
    XXL: '5.6rem',
    XXXL: '9.06rem',
  },
  radius: {
    primary: '0.5rem',
    secondary: '0.25rem',
  },
  color: {
    primary: '#cc2e78',
    secondary: '#1845ac',
    warning: '#E7B075',
    alert: '#fc6868',
    success: '#89C88C',
    focusRing: '#D7DADC',
  },
  backgroundColor: {
    primary: '#181b22',
    secondary: '#363540',
    card: '#29292f',
    innerCard: '#1d2026',
    disabled: '#474747',
    brand: '#bb2a6e', // slightly darker than color.primary for better contrast
  },
  borderColor: {
    primary: '#D7DADC',
    secondary: '#474747',
    tertiary: '#25292f',
    disabled: '#c9c9c9',
  },
  fontColor: {
    plain: {
      primary: '#ffffff',
      secondary: '#b9babb',
      tertiary: '#6e6f71',
    },
    inverted: {
      primary: '#000000',
      secondary: '#737373',
      tertiary: '#979797',
    },
    link: {
      normal: '#62a9ff',
      visited: '#ae2eae',
      hover: '#b9babb',
    },

    disabled: '#c9c9c9',
    calendarWeekday: '#d64a4a',
    footerText: '#8a8a8a',
  },
  effect: {
    dimBackground: `rgba(0, 0, 0, 0.75)`, //as backgroundColor
  },
  transition: {
    hoverColor: 'background 0.2s ease',
  },
  font: {
    primary: "'Open Sans', sans-serif",
  },
  /** https://type-scale.com/ currently using the major third scale (1.25) */
  // TODO: explore a fluid font scale https://codepen.io/jh3y/pen/KKLNLxW
  // also for reference https://utopia.fyi/blog/css-modular-scales/
  fontSize: {
    XXS: '0.512rem',
    XS: '0.64rem',
    S: '0.8rem',
    M: '0.9rem',
    L: '1.563rem',
    XL: '1.953rem',
    XXL: '2.441rem',
    XXXL: '3.051rem',
    // Probably a good idea to define a global font size as well (maybe split into body and headings?)
  },
  pageWidth: {
    S: '343px',
    M: '556px', // base divided by golden ratio
    L: '900px', // base
    LAccountedForPadding: '874px', // pageWidth.L - size.M
  },
  navbarHeight: {
    mobile: '3rem',
    desktop: '4rem',
  },
  stickyThreshold: {
    primary: 77, // Relates to navbar height and any top margin/padding applied to page
  },
  animation: {
    fadeIn: keyframes`
      from { opacity: 0; }
      to { opacity: 1; }
      `,
  },
}
